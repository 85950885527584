#debug_layer {
    display: none;
    box-sizing: border-box;
    position: absolute;
    height: 90%;
    width: 90%;
    top: 5%;
    left: 5%;
    border-radius: 10px;
    overflow: hidden;
    padding: 1%;

    .wrapper {
        box-sizing: border-box;
        display: flex;
        overflow: hidden;

        dl {
            padding: 10px;
            margin: 0;
            background: white;
            border: 2px solid gray;
            border-radius: 10px;

            dt {
                color: grey;
            }

            dd {
                margin-inline-start: 20px;
            }
        }

        .console {
            box-sizing: border-box;
            overflow: hidden;
            width: 100%;
            background: white;
            border: 2px solid gray;
            border-radius: 10px;
            list-style: none;
            margin: 0;
            padding: 0;

            .warn {
                >i:after {
                    content: "️️⚠️ ";
                }
                background-color: #ededc4;
            }
            .error {
                >i:after {
                    content: "🚨️ ";
                }
                background-color: #f1d3d3;
            }
            li {
                overflow: hidden;
                padding: 10px;
            }
        }
        .muted {
            color: dimgrey;
        }
    }

}