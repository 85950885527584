.quadrant_1 {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    transform: scale(0.5);
    transform-origin: top left;
    position: absolute;
}

.quadrant_2 {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    transform: scale(0.5);
    transform-origin: top right;
    position: absolute;

}

.quadrant_3 {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    transform: scale(0.5);
    transform-origin: bottom left;
    position: absolute;
}

.quadrant_4 {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    transform: scale(0.5);
    transform-origin: bottom right;
    position: absolute;
}

.emptyState {
    width: 1920px;
    height: 1080px;
    display: flex;
    justify-content: center;

    img {
        padding: 200px;
        height: auto;
    }
}