.ZoomTextCta {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 40px 65px 130px;
    
    .desc {
        position: relative;
        width: 100%;
        height: 100%;
        
        .caption, .DefaultTextCta {
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            max-height: 100%;
            background: transparent;
            color: var(--zoom-text-color);
            backface-visibility: hidden;
            transform-style: preserve-3d;
        }

        .caption {
            transform: rotateY(180deg);
        }

    }

    .pwby-logo-container {
        box-sizing: border-box;
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 100%;
        text-align: center;

        .pwby-logo {
            display: inline-block;
            height: 80px;
            text-align: center;
            font-size: 80px;

            .SocialZoom.vertical & {
                height: 70px;
                font-size: 70px;
            }
        }

    }
}