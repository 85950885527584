
.saycheese-cta-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    
    .header {
        font-family: 'Cabin', sans-serif;
        height: 30%;
        width: 75%;
        font-weight: 700;
        box-sizing: border-box;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .sc-cta-container {
            position: relative;
            flex-grow: 1;
            margin: 5px 0 30px 0;
        }

        .CtaText {
            display: flex;
            align-items: center;
            height: 100%;
            line-height: 100%;
            justify-content: center;
        }

        .sc-logo-container {
            height: 30%;

            .sc-cta-logo {
                max-height: 100%;
                object-fit: contain;
            }
        }
    }
    .qr-container {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;

        .qr {
            border-radius: 30px;
            background-color: white;
            padding: 24px;
            margin-bottom: 80px;
        }
    }

    &.small {
        height: 90%;

        .qr-container {
            width: 80%;
            margin-bottom: 14px;
    
            .qr {
                margin-bottom: 32px;
            }
        }
    }

    &.relaxed {
        width: 100%;

        .header {
            height: 40%;
        }
        
        .qr-container {

            .qr {
                margin-bottom: 80px;
            }
        }
    }

    &.long-cta {
        flex-direction: row;

        .header {
            height: 90%;
            padding: 35px;
        }

        .qr-container {
            border: none;
            margin: 0;

            .qr {
                margin: 0;
            }
        }
    }
}

.saycheese-full-screen {
    width: 100%;
    height: 100%;

    .saycheese-cta-container {
        flex-direction: row;

        .header {
            padding: 80px;
            height: 50%;
            width: 100%;

            .sc-logo-container {
                height: 50%;
            }
        }   

        .qr-container {
            flex-direction: column;
            width: 100%;
            padding-top: 80px;

            .top {
                font-size: 42px;
            }
            
            .bottom {
                text-align: center;
                margin-bottom: 18px;
                font-size: 56px;
                font-weight: bold;
            }

            .qr-text {
                font-size: 48px;
                margin-bottom: 14px;
            }

            .pwby-logo {
                font-size: 52px;
            }
            
        }

    }

    .vertical {
        flex-direction: column;
        
        .qr-container {
            width: 80%;
        }
        .pwby-logo {
            margin-bottom: 30px;
        }
    }
    
}
