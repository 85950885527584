.Jukin {
    height: 100%;
    width: 100%;
    color: white;
    font-size: 40px;
    display: flex;
    justify-content: center;
    background-color: black;
    
    align-items: center;
    .intro_animation {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 6;

        &.hidden {
            opacity: 0;
        }

    }
    
    .start_plate, .overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 5;
        &.hidden {
            opacity: 0;
        }
    }
    
    .finish_plate {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 5;
        transition: opacity 0.3s ease-in;
        &.hidden {
            opacity: 0;
        }
    }
}