#pwa-install-prompt-root {
    .pwa-install-prompt {
        background: white;
        padding: 34px 43px 27px 43px;
        border: 1px solid #BFBFBF;
        border-radius: 6px;
        z-index: 999;
        position: absolute;
        right: 105px;
        top: 63px;

        h5, p {
            color: #707070;
            font-family: "Cabin", sans-serif;
            font-size: 12px;
            letter-spacing: 0;
            text-align: center;
            margin: 0;
        }

        button {
            cursor: pointer;
        }

        button:focus {
            outline: none;
        }

        .pwa-install-prompt__header {
            button {
                position: absolute;
                right: 19px;
                top: 15px;
                background: none;
                border: none;
            }
        }

        .pwa-install-prompt__button {
            display: block;
            margin: 17px auto 0 auto;
            border-radius: 17px;
            padding: 8px 26px;
            background: #3AACE2;
            font-family: "Cabin", sans-serif;
            color: white;
            border: none;
        }
    }
}
