// Base colors
:root {
    --background: #000000;
    --background-color: #000000;
    --cta-background: #2f2f2f;
    --cta-background-color: #2f2f2f;
    --text-color: #ececec;
    --hashtags-color: #17b0f4;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_white.png');
    --upshow-tv-logo-upnext: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_white.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #17b0f4;
    --long-hashtag-display-separator: block;
    --zoom-background: #2f2f2f;
    --zoom-background-color: #2f2f2f;
    --zoom-text-color: #ececec;
}

@import "./overscan.scss";

// Themes
@import "./themes.scss";
@font-face {
    font-family: 'upshow';
    src: url('public/fonts/upshow.eot?41842410');
    src: url('public/fonts/upshow.eot?41842410#iefix') format('embedded-opentype'), url('public/fonts/upshow.woff2?41842410') format('woff2'), url('public/fonts/upshow.woff?41842410') format('woff'), url('public/fonts/upshow.ttf?41842410') format('truetype'), url('public/fonts/upshow.svg?41842410#upshow') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roobert';
    src: url('./assets/fonts/Roobert-Regular.otf') format('opentype'); /* 400 - Regular font-weight */
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roobert';
    src: url('./assets/fonts/Roobert-Bold.otf') format('opentype'); /* 900 - Bold font-weight */
    font-weight: 900;
    font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */


/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */


/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'upshow';
    src: url('../font/upshow.svg?41842410#upshow') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "upshow";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-identify:before {
    content: '\e815';
}


/* '' */

.icon-twitter:before {
    content: '\f099';
}


/* '' */

.icon-instagram:before {
    content: '\e16d';
}


@font-face {
    font-family: 'networks-icons';
    src:  url('public/fonts/NetworksIcons.eot?z9r9a7');
    src:  url('public/fonts/NetworksIcons.eot?z9r9a7#iefix') format('embedded-opentype'),
      url('public/fonts/NetworksIcons.ttf?z9r9a7') format('truetype'),
      url('public/fonts/NetworksIcons.woff?z9r9a7') format('woff'),
      url('public/fonts/NetworksIcons.svg?z9r9a7#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
[class^="network-"], [class*=" network-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'networks-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.network-tw:before {
    content: "\e900";
}

.network-ig:before {
    content: "\e901";
}

@font-face {
    font-family: 'pwby';
    src:  url('public/fonts/pwby.eot?8m3bui');
    src:  url('public/fonts/pwby.eot?8m3bui#iefix') format('embedded-opentype'),
      url('public/fonts/pwby.ttf?8m3bui') format('truetype'),
      url('public/fonts/pwby.woff?8m3bui') format('woff'),
      url('public/fonts/pwby.svg?8m3bui#pwby') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="pwby"], [class*=" pwby"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'pwby' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .pwby-logo:before {
    content: "\e900";
  }
/* '' */

@font-face {
    font-family: 'Circular';
    src: url('public/fonts/CircularStd-Black.otf');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Circular';
    src: url('public/fonts/CircularStd-BlackItalic.otf');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Circular';
    src: url('public/fonts/CircularStd-Bold.otf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Circular';
    src: url('public/fonts/CircularStd-BoldItalic.otf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Circular';
    src: url('public/fonts/CircularStd-Book.otf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Circular';
    src: url('public/fonts/CircularStd-BookItalic.otf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Circular';
    src: url('public/fonts/CircularStd-Medium.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Circular';
    src: url('public/fonts/CircularStd-MediumItalic.otf');
    font-weight: 500;
    font-style: italic;
}

html {
    &.ChromeOS {
        cursor: none;
    }
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    box-sizing: border-box;
}

body {
    font-family: 'Circular', sans-serif;
    margin: 0;
    background-color: var(--background-color);
    overflow: hidden;
    box-sizing: border-box;
    width: 1920px;
    height: 1080px;

    &.vertical {
        width: 1080px;
        height: 1920px;
    }

    &.blackBackground {
        background-color: black;
    }
}

.Activation {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background: linear-gradient(#262626, #000000);
    font-family: "Roobert", sans-serif;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;

    padding: 30px;
    color: #ffffff;
    background-color: #353535;

    .instructions {
        font-size: 65px;
        width: 100%;
        height: 195px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        text-align: center;

        font-weight: 400;
    }

    .code {
        font-size: 200px;
        font-weight: 900;
        line-height: 200px;
        height: 200px;
        width: 100%;
    }

    .contact {
        height: 195px;
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 45px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        text-align: center;

        font-family: "Roobert", sans-serif;
    }

    .logo {
        position: absolute;
        bottom: 80px;
        left: 0;
        right: 0;
        width: 100%;
    }

    .hidden {
        display: none;
    }

    #checks-button {
        position: absolute;
        left: 50px;
        bottom: 50px;
        background: none;
        border: none;
        cursor: pointer;
    }
}

.EverpassInactive {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding-bottom: 140px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: end;
    .content {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        height: 100%;
        max-height: 67%;
    }

    background: linear-gradient(#262626, #000000);

    font-family: "Roobert", sans-serif;
    text-align: center;
    color: #F6F6F6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .first {
        font-size: 58px;
        padding-bottom: 9vh;
    }

    .second {
        font-size: 28px;
        line-height: 20px;
    }
}

@media (orientation: portrait) {
    .EverpassInactive {
        .content {
            max-height: 57%;
        }
        .first {
            padding-bottom: 3vh;
        }
    }
}

#waiting {
    box-sizing: border-box;
    height: 100%;
    width: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    background: linear-gradient(#262626, #000000);

    font-family: "Roobert", sans-serif;
    text-align: center;
    color: #F6F6F6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .first {
        font-size: 58px;
        padding-top: 6vh;
    }

    .second {
        padding-top: 3vh;
        font-size: 32px;
    }
}

// Identify
@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#identify_notification {
    position: absolute;
    bottom: 5%;
    left: 5%;
    z-index: 666;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    background-color: var(--background-color);
    box-shadow: 0 0 30px var(--text-color);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    i:before {
        font-weight: 900;
        font-size: 130px;
        content: "i";
        color: var(--hashtags-color);
    }

    span {
        position: absolute;
        top: 40px;
        left: 250px;
        color: var(--hashtags-color);
        font-weight: 900;
        font-size: 100px;
        width: 900px
    }

    animation: flickerAnimation 2s infinite;
}

// Network down
#network-down-indicator {
    border-radius: 50%;
    border: 1px solid #000000;
    background-color: #FF0000;
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 100000;
    left: 25px;
    top: 25px;
    display: none;

    &.no-network {
        display: block;
    }
}

// background music
#bg-music {
    position: absolute;
    z-index: 1;
}

#bg-music video {
    visibility: hidden;
}

@keyframes fadeInRightBig {
    from {
        right: -2000px;
    }

    to {
        right: 0px;
    }
}

@keyframes fadeOutRightBig {
    from {
        right: 0px;
    }
  
    to {
        right: -2000px;
    }
}

#fixed-content {
    .fadeInRightBig {
        animation-name: fadeInRightBig;
        animation-duration: 1000ms;
        position: fixed;
        right: 0px;
        height: 100%;
        z-index: 1;
        width: auto;
    }

    .fadeOutRightBig {
        animation-name: fadeOutRightBig;
        animation-duration: 1500ms;
        position: fixed;
        right: -2000px;
        height: 100%;
        z-index: 1;
        width: auto;
    }
}

@keyframes scaleDown {
    from {
        transform: none;
    }

    to {
        transform: scale(0.66666667); 
        transform-origin: left;
    }
}

@keyframes scaleUp {
    from {
        transform: scale(0.66666667); 
        transform-origin: left;
    }

    to {
        transform: none;
    }
}

#root {
    &.scaleDown {
        transform: scale(0.66666667);
        transform-origin: left;
        animation-name: scaleDown;
        animation-duration: 1500ms;
    }

    &.scaleUp {
        transform: none; 
        animation-name: scaleUp;
        animation-duration: 2000ms;
    }
}

#audio-stats {
    background-color: #00000047;
    color: white;
    padding: 20px;
    position: fixed;
    bottom: 0px;
    right: 160px;
}

#vu-meter {
    position: absolute;
    z-index: 999999;
    right: 0px;
    bottom: 0px;
    border: 3px solid white;
}

#checksIframe {
    width: 100%;
    height: 100%;
    border: 0;
}

iframe > iframe {
    position: absolute;
    top: 0;
    width: inherit !important;
    height: inherit !important;
}

#checksContainer {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
