#overscan-red-zone {
  display: none;
  position: absolute;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: red solid 2.5vh ;
  border-bottom: red solid 2.5vh ;
  border-left: red solid 2.5vw ;
  border-right: red solid 2.5vw ;
  background-color: rgba(0, 250, 0, 0.3);
  box-sizing: border-box;
  opacity: 0.6;

  #overscan-yellow-zone{
    border-top: yellow solid 2.5vh ;
    border-bottom: yellow solid 2.5vh ;
    border-left: yellow solid 2.5vw ;
    border-right: yellow solid 2.5vw ;
    position: absolute;
    z-index: 100000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box
  }
}